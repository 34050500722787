/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FolderSuccessModal from '../Modals/FolderSuccessModal';
import { File } from './MyDocuments';

interface MyDocumentsTableRowProps {
    uploadedFile: File;
    navigate: (path: string, options?: object) => void;
    editingFile: any;
    setEditingFile: (file: any) => void;
    setName: (name: string) => void;
    setDescription: (description: string) => void;
    name: string;
    description: string;
    updateDocument: (id: number, name: string, description: string, type: string, parent: number | null) => Promise<void>;
    fetchFiles: () => void;
    setDeleteConfirmation: (value: boolean) => void;
    setDocumentToDelete: (file: any) => void;
    handleSelectionChange: (id: number, isSelected: boolean) => void;
    selectedFiles: number[];
    draggableProps?: any;
    dragHandleProps?: any;
    ref?: React.Ref<HTMLTableRowElement>;
    toggleFolder?: (id: any, isReverse: boolean) => void;
    selectedFolder: { [key: string]: number | null } | null;
    prevFolder: { [key: string]: number | null } | null;
    toggleMenu: (fileId: number) => void;
    openDropdown: number;
    setShowMoveModal: (value: boolean) => void;
    setMoveFile: (file: any) => void;
    setShowCreateSuccessModal: (value: boolean) => void;
}

const MyDocumentsTableRow = forwardRef<HTMLTableRowElement, MyDocumentsTableRowProps>(
    (
        {
            uploadedFile,
            navigate,
            editingFile,
            setEditingFile,
            setName,
            setDescription,
            name,
            description,
            updateDocument,
            fetchFiles,
            setDeleteConfirmation,
            setDocumentToDelete,
            handleSelectionChange,
            selectedFiles,
            draggableProps,
            dragHandleProps,
            toggleFolder,
            selectedFolder,
            prevFolder,
            toggleMenu,
            openDropdown,
            setShowMoveModal,
            setMoveFile,
            setShowCreateSuccessModal
        },
        ref
    ) => {
        const [isChecked, setIsChecked] = useState(selectedFiles.includes(uploadedFile.id));
        const isFolder = uploadedFile.type === 'folder';
        const isRootFolder = uploadedFile.folder === null;
        const [showModal, setShowModal] = useState(false);
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        }

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        useEffect(() => {
            setIsChecked(selectedFiles.includes(uploadedFile.id));

            if (isRootFolder && toggleFolder) {
                toggleFolder(uploadedFile.id, false);
            }
        }, [selectedFiles, uploadedFile.id, isRootFolder, toggleFolder]);

        const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            handleSelectionChange(uploadedFile.id, e.target.checked);
            setIsChecked(e.target.checked);
        };

        const handleFileClick = () => {
            if (isFolder && toggleFolder) {
                toggleFolder({ [uploadedFile.name]: uploadedFile.id }, false);
            } else if (!editingFile) {
                navigate(`/apps/lexim-cabinet/${uploadedFile.name}`, {
                    state: { uploadedFile, selectedFolder, prevFolder },
                });
            }
        };

        const handleDropdownClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            toggleMenu(uploadedFile.id);
        };

        const handleSaveEdit = (e: React.MouseEvent) => {
            e.stopPropagation();
            updateDocument(uploadedFile.id, name, description, uploadedFile.type, uploadedFile.parent_id).then(() => {
                fetchFiles();
                setEditingFile(null);
            });
        };

        const handleEditClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            if (editingFile === uploadedFile) {
                setEditingFile(null);
            } else {
                setEditingFile(uploadedFile);
                setName(uploadedFile.name);
                setDescription(uploadedFile.doc_name);
            }
        };

        const handleMoveClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            setMoveFile(uploadedFile);
            setShowMoveModal(true);
        };

        const handleDeleteClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            if (uploadedFile.files && uploadedFile.files.length > 0) {
                setShowModal(true);
                setShowCreateSuccessModal(false);
                setShowMoveModal(false);
            } else {
                setDeleteConfirmation(true);
                setDocumentToDelete(uploadedFile);
            }
        };

        return (
            <>
                <tr
                    ref={ref}
                    {...draggableProps}
                    {...dragHandleProps}
                    style={{
                        cursor: 'pointer',
                        paddingBottom: '0',
                        ...(draggableProps?.style || {}),
                    }}
                    onClick={handleFileClick}
                    className={isHovered ? 'highlight-hover' : ''}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {!isFolder ? (
                        <td style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()} className="text-start pt-7">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isChecked}
                                    style={{ border: '1px solid #000000', marginLeft: '7px', cursor: 'pointer' }}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                        </td>
                    ) : (
                        <td></td>
                    )}

                    <td
                        className={`text-start pt-7 ${isFolder && editingFile !== uploadedFile && 'fs-4'}`}
                        {...(isFolder ? { colSpan: 2 } : {})}
                    >
                        {editingFile === uploadedFile ? (
                            <div className="p-2">
                                <textarea
                                    rows={isFolder ? 1 : 2}
                                    maxLength={500}
                                    value={isFolder ? name : description}
                                    onChange={(e) => (isFolder ? setName(e.target.value) : setDescription(e.target.value))}
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ width: '100%', padding: '10px', resize: 'none' }}
                                />
                                <div className="text-end">{isFolder ? `${name.length}/100` : `${description.length}/500`}</div>
                            </div>
                        ) : isFolder ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <KTIcon iconName="folder" className="fs-1 duotone text-primary me-2" />
                                <span>{uploadedFile.name}</span>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                {uploadedFile.is_genai_available === 1 && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="genai-tooltip">AI features are available</Tooltip>}
                                    >
                                        <i className="bi bi-patch-check-fill fs-1 fw-bold text-success me-3"></i>
                                    </OverlayTrigger>
                                )}
                                {uploadedFile.doc_name}
                            </div>
                        )}
                    </td>

                    {!isFolder && (
                        <td className={`text-start pt-7`}>
                            {editingFile === uploadedFile ? (
                                <div className="p-2">
                                    <textarea
                                        rows={2}
                                        maxLength={100}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}
                                        style={{ width: '100%', padding: '10px', resize: 'none' }}
                                    />
                                    <div className="text-end">{name.length}/100</div>
                                </div>
                            ) : (
                                <div>
                                    <span>{uploadedFile.name}</span>
                                </div>
                            )}
                        </td>
                    )}
                    

                    <td className="text-end pt-7">
                        {new Date(uploadedFile.create_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                    </td>

                    <td className="text-end pt-7 ps-5" onClick={(e) => e.stopPropagation()} style={{ cursor: 'default' }}>
                        <div className="dropdown">
                            <button
                                className="btn btn-light btn-active-light-primary btn-sm dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButton-${uploadedFile.id}`}
                                data-bs-toggle="dropdown"
                                aria-expanded={openDropdown === uploadedFile.id ? 'true' : 'false'}
                                onClick={handleDropdownClick}
                            >
                                Actions
                            </button>

                            <ul className={`dropdown-menu dropdown-menu-end`} aria-labelledby={`dropdownMenuButton-${uploadedFile.id}`}>
                                {editingFile === uploadedFile && (
                                    <li>
                                        <button className="dropdown-item fw-bold d-flex align-items-center" onClick={handleSaveEdit}>
                                            <i className="fas fa-check fs-5 text-success ms-4 me-2"></i>
                                            Save Edit
                                        </button>
                                    </li>
                                )}

                                {uploadedFile.name !== "Recently Deleted" && (
                                    <>
                                        <li>
                                            <button className="dropdown-item fw-bold d-flex align-items-center" onClick={handleEditClick}>
                                                {editingFile === uploadedFile ? (
                                                    <>
                                                        <i className="fas fa-times fs-5 text-danger ms-4 me-3"></i>
                                                        Cancel Edit
                                                    </>
                                                ) : (
                                                    <>
                                                        <KTIcon iconName="pencil" className="fs-5 duotone text-primary ms-3 me-2" />
                                                        Edit
                                                    </>
                                                )}
                                            </button>
                                        </li>
                                    </>
                                )}

                                <li>
                                    <button className="dropdown-item fw-bold d-flex align-items-center me-3" onClick={handleDeleteClick}>
                                        <KTIcon iconName="trash" className="fs-5 duotone text-danger ms-3 me-2" />
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>

                <tr
                    ref={ref}
                    {...draggableProps}
                    {...dragHandleProps}
                    style={{
                        cursor: 'pointer',
                        ...(draggableProps?.style || {}),
                    }}
                    rowSpan={2}
                    onClick={handleFileClick}
                    className={isHovered ? 'highlight-hover' : ''}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <td></td>
                    <td colSpan={4} className={`text-start ${uploadedFile.mini_summary && uploadedFile.mini_summary !== '' && 'text-gray-700 pb-9 pe-13'}`}>
                        {uploadedFile.mini_summary && uploadedFile.mini_summary !== '' && uploadedFile.mini_summary}
                    </td>
                </tr>

                {showModal && (
                    <FolderSuccessModal
                        showToast={showModal}
                        onClose={() => setShowModal(false)}
                        message="Folder still contains files or subfolders. Please empty contents of folder before deleting."
                    />
                )}
            </>

        );
    }
);

export default MyDocumentsTableRow;
