import React from 'react';
// import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// import axios from 'axios';
import DocumentsListToolbar from '../pages/Document/Components/DocumentsListToolbar';

function DocumentListWrapper({ filteredDocuments, topRow = '', secondaryRow = [], thirdRow = [], bottomRow = [],
    summaryRow = '', linkTo, links = [], isInternational = false,
    trackedDocuments = [], setTrackedDocuments = () => { },
    docType = '', setAlert = () => { },
    state = {} }) {

    // const handleViewButtonPDFClick = (documentId) => {
    //     const url = `/viewpdf?id=${documentId}`
    //     if (isInternational) {
    //         sessionStorage.setItem('isInternational', true);
    //     }
    //     else {
    //         sessionStorage.setItem('isInternational', false);
    //     }
    //     window.open(url, '_blank')
    // };

    // const handleDownloadButtonPDFClick = async (documentId) => {
    //     try {
    //         const response = await axios.get(`/reg/v1/document/international/viewpdfa/${documentId}`);
    //         const presignedUrl = response.data.url;
    //         return presignedUrl;
    //     } catch (error) {
    //         console.error('Error downloading PDF:', error);
    //         return null;
    //     }
    // };
    const renderRow = (document, row, keyColor, valueColor) => (
        <div className="d-flex align-items-center">
            {row.map(([label, key, func], idx) => {
                const value = func ? func(document[key]) : document[key];

                // Skip rendering if the value is null, undefined, or an empty string
                // if (value === null || value === '') {
                //     return null;
                // }

                return (
                    <React.Fragment key={key}>
                        <h5 className="card-title pb-2" style={{ color: keyColor }}>
                            {label}: <span className="" style={{ color: valueColor }}>{value === null || value === '' ? 'None' : value}</span>
                        </h5>
                        {idx < row.length - 1 && <span className="card-title pb-2 px-2" style={{ color: keyColor }}>|</span>}
                    </React.Fragment>
                );
            })}
        </div>
    );
    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <table className="table">
                <tbody>
                    {filteredDocuments.map((document, index) => (
                        <tr key={index}>
                            <td colSpan="6">
                                <div className="card card-custom card-flush" style={{ borderTop: `6px solid ${index % 2 === 0 ? '#0088cc' : '#00aaff'}` }}>
                                    <div className="card-header d-flex justify-content-between align-items-start mx-4" style={{ padding: '1rem' }}>
                                        <h5 className="card-title mt-5 mb-3 ms-1 m-0 col-9" style={{ flexGrow: 1 }}>
                                            {(document.is_genai_exists === 1 || document.is_genai_available === 1) && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="genai-tooltip">AI features for this document are available</Tooltip>}
                                                >
                                                    {({ ref, ...triggerHandler }) => (
                                                        <div
                                                            {...triggerHandler}
                                                            className="me-2"
                                                        >
                                                            {/* <KTIcon iconName='virus' iconType="solid" className='fs-2x me-3 text-success' /> */}
                                                            <i ref={ref} className="bi  bi-patch-check-fill fs-1 fw-bold text-success"></i>
                                                        </div>
                                                    )}
                                                </OverlayTrigger>
                                            )}
                                            {docType === 'warning-letters' ? (
                                                <>
                                                    Company Name: {''}
                                                    <span className="" style={{ color: '#0077b6' }}>
                                                        <Link to={document.warning_letter_url} className="text-decoration-underline ms-1" style={{ color: '#0077b6' }} target="_blank" rel="noopener noreferrer">
                                                            {document.company_name}
                                                        </Link>
                                                    </span>
                                                </>
                                            ) : docType === 'debarment' ? (
                                                <>
                                                    Name: {''}
                                                    <span className="ms-1" style={{ color: '#0077b6' }}>
                                                        {document.document_title.replace(/;/g, ':').split(':')[0]}
                                                    </span>
                                                    <span>
                                                        {document.effective_date}
                                                    </span>
                                                </>
                                            ) : docType === 'notices' ? (
                                                <div
                                                    className="text-wrap"
                                                    style={{
                                                        color: '#0063cc',
                                                        lineHeight: '1.2',
                                                        wordBreak: 'break-word',
                                                        overflowWrap: 'break-word',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {document[topRow]}
                                                </div>
                                            ) : (
                                                <Link
                                                    to={isInternational ? `/international/${document[linkTo]}` : `/document/${document[linkTo]}`}
                                                    className="text-decoration-underline text-wrap"
                                                    style={{
                                                        color: '#0063cc',
                                                        lineHeight: '1.2',
                                                        wordBreak: 'break-word',
                                                        overflowWrap: 'break-word',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {document[topRow]}
                                                </Link>

                                            )}
                                        </h5>
                                        {docType !== 'warning-letters' && (
                                            <DocumentsListToolbar
                                                document={document}
                                                trackedDocuments={trackedDocuments}
                                                setTrackedDocuments={setTrackedDocuments}
                                                docType={docType}
                                                setAlert={setAlert}
                                                isInternational={isInternational}
                                                link={document[links[0]]}
                                                id={document[links[1]]}
                                            />
                                        )}
                                    </div>
                                    <div className="card-body card-scroll pt-0 mt-n4">
                                        {secondaryRow && (
                                            docType === 'debarment' ?
                                                renderRow(document, secondaryRow, '#014f86', '#0077b6') :
                                                renderRow(document, secondaryRow, '#AA4A44', '#c5736d')

                                        )}

                                        {thirdRow && (
                                            docType === 'warning-letters' || docType === 'debarment' ?
                                                renderRow(document, thirdRow, '#AA4A44', '#c5736d') :

                                                renderRow(document, thirdRow, '#014f86', '#0077b6')

                                        )}
                                        {bottomRow && renderRow(document, bottomRow, '#505050', '#707070')}
                                    </div>
                                    {document[summaryRow] !== null && document[summaryRow] !== '' && (
                                        <div className="d-flex align-items-center px-9 mt-n8">
                                            <h5 className="card-title pb-7 text-gray-600" style={{ fontWeight: 'normal' }}>
                                                {document[summaryRow]}
                                            </h5>
                                        </div>

                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DocumentListWrapper;
