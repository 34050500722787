import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import MyDocumentsTableHeader from './MyDocumentsTableHeader';
import MyDocumentsTableRow from './MyDocumentsTableRow';
import './grouped-table.css';



const MyDocumentsTable = ({
    filteredFiles,
    selectedFiles,
    sortConfig,
    handleHeaderClick,
    navigate,
    editingFile,
    setEditingFile,
    setName,
    setDescription,
    name,
    description,
    updateDocument,
    fetchFiles,
    setDeleteConfirmation,
    setDocumentToDelete,
    handleSelectionChange,
    compareDocuments,
    setFilteredFiles,
    startIndex,
    endIndex,
    toggleFolder,
    selectedFolder,
    setTotalPages,
    itemsPerPage,
    prevFolder,
    setShowMoveModal,
    setMoveFile,
    handleMoveFolder,
    setShowCreateSuccessModal
}) => {

    const [openDropdown, setOpenDropdown] = useState<number>(-1);

    const toggleMenu = (fileId: number) => {

        if (openDropdown === fileId) {

            setOpenDropdown(-1); // Close the dropdown if the same one is clicked

        } else {

            setOpenDropdown(fileId); // Open the dropdown for the clicked file

        }

        const allDropdowns = document.querySelectorAll('.dropdown-menu');

        allDropdowns.forEach((dropdown) => {

            dropdown.classList.remove('show');

        });

        const targetDropdown = document.getElementById(`dropdownMenuButton-${fileId}`)?.nextElementSibling;
        targetDropdown?.classList.toggle('show');
    };

    const onDragEnd = (result) => {

        const { source, destination } = result;

        if (!destination) return;

        const updatedFiles = [...currentFiles];
        const sourceIndex = source.index;
        const destinationFolderId = updatedFiles[destination.index].id;

        const destinationFolder = updatedFiles.find(
            (file) => file.id === destinationFolderId && file.type === 'folder'
        );

        if (destinationFolder) {
            handleMoveFolder(destinationFolder.id, updatedFiles[sourceIndex]);
        }

        setFilteredFiles(updatedFiles);

    };

    const findFolderRecursively = (files, folder) => {
        for (const file of files) {

            if (file.type === 'folder') {

                if (file.name === Object.keys(folder)[0] && file.id === Object.values(folder)[0]) {
                    return file.files || [];
                }

                const found = findFolderRecursively(file.files || [], folder);

                if (found.length > 0) {
                    return found;
                }
            }
        }

        return [];

    };

    const currentFiles = selectedFolder && Object.values(selectedFolder)[0]
        ? findFolderRecursively(filteredFiles, selectedFolder).slice(startIndex, endIndex)
        : filteredFiles.slice(startIndex, endIndex);

    useEffect(() => {

        if ((selectedFolder && (Object.values(selectedFolder)[0] === null)) || !selectedFolder) {
            setTotalPages(Math.ceil(filteredFiles.length / itemsPerPage));
        }
        else {
            let filtered = filteredFiles.filter((file) => file.type === 'folder' && file.name === Object.keys(selectedFolder)[0]).map((folder) => folder.files).flat()
            setTotalPages(Math.ceil(filtered.length / itemsPerPage));
        }
    }, [filteredFiles, currentFiles, selectedFolder, itemsPerPage, setTotalPages]);

    return (

        <div className="table-responsive px-5 mb-5" style={{ height: `calc(${currentFiles.length > 0 ? currentFiles.length * 33.5 : 100}vh - 100px)`, maxHeight: '80vh', overflowY: 'auto' }}>
            <DragDropContext onDragEnd={onDragEnd}>
            <table className="table table-striped-group table-rounded border gy-5 gs-7 align-middle dataTable no-footer">
                <MyDocumentsTableHeader
                sortConfig={sortConfig}
                handleHeaderClick={handleHeaderClick}
                compareDocuments={compareDocuments}
                selectedFiles={selectedFiles}
                />

                <Droppable droppableId="table-body" direction="vertical" type="LIST">
                {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {currentFiles.length === 0 ? (
                        <tr>
                        <td colSpan={6} className="text-dark fw-semibold fs-5" style={{ textAlign: 'center' }}>
                            No records found
                        </td>
                        </tr>
                    ) : (
                        currentFiles.map((uploadedFile, index) => (
                        <React.Fragment key={uploadedFile.id.toString() + uploadedFile.type}>
                            <Draggable draggableId={uploadedFile.id.toString() + uploadedFile.type} index={index}>
                            {(provided) => (
                                <MyDocumentsTableRow
                                ref={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                uploadedFile={uploadedFile}
                                navigate={navigate}
                                editingFile={editingFile}
                                setEditingFile={setEditingFile}
                                setName={setName}
                                setDescription={setDescription}
                                name={name}
                                description={description}
                                updateDocument={updateDocument}
                                fetchFiles={fetchFiles}
                                setDeleteConfirmation={setDeleteConfirmation}
                                setDocumentToDelete={setDocumentToDelete}
                                handleSelectionChange={handleSelectionChange}
                                selectedFiles={selectedFiles}
                                toggleFolder={toggleFolder}
                                selectedFolder={selectedFolder}
                                prevFolder={prevFolder}
                                toggleMenu={toggleMenu}
                                openDropdown={openDropdown}
                                setShowMoveModal={setShowMoveModal}
                                setMoveFile={setMoveFile}
                                setShowCreateSuccessModal={setShowCreateSuccessModal}
                                />
                            )}
                            </Draggable>
                        </React.Fragment>
                        ))
                    )}
                    <div style={{ visibility: 'hidden', height: 0, display: 'none' }}>
                        {provided.placeholder}
                    </div>
                    </tbody>
                )}
                </Droppable>
            </table>
            </DragDropContext>
        </div>
    );
};

export default MyDocumentsTable;

