import { KTIcon } from '../../../../_metronic/helpers';
import CommentFilter from './CommentFilter';
import CommentsCard from './CommentsCard';
import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../modules/auth';
import HourGlass from '../../Loading/HourGlassSpinner/HourGlass';

const DiscussionViewer = ({  documentId, doc_source, txt_documentId }) => {
    const [inputValue, setInputValue] = useState('');
    const [comments, setComments] = useState([]);
    const [loading] = useState(false);
    const { currentUser } = useAuth();
    const [searchInput, setSearchInput] = useState('');
    const [commentSortOrder, setCommentSortOrder] = useState('desc');
    const [filteredComments, setFilteredComments] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [areFiltersActive, setAreFiltersActive] = useState(false);
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null
    });

    const handleFilterChange = (filterName, filterValue) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: filterValue
        }));
    };

    const toggleCommentSortOrder = useCallback(() => {
        let newSortOrder = commentSortOrder === 'desc' ? 'asc' : 'desc';
        setCommentSortOrder(newSortOrder);
        let sortedComments = [...comments];
        sortedComments.sort((a, b) => {
            if (newSortOrder === 'asc') {
                return new Date(a['create_date']).toLocaleDateString('en-US', { timeZone: 'UTC' }) - new Date(b['create_date'].toLocaleDateString('en-US', { timeZone: 'UTC' }));
            } else {
                return new Date(b['create_date']).toLocaleDateString('en-US', { timeZone: 'UTC' }) - new Date(a['create_date'].toLocaleDateString('en-US', { timeZone: 'UTC' }));
            }
        });
        setComments(sortedComments);
    }, [commentSortOrder, comments]);

    // Determine the correct ID to use for API requests
    const getDocumentId = doc_source === 1 ? txt_documentId : documentId;


    const fetchComments = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/reg/v1/user/discussions/${currentUser?.organization}/${getDocumentId}/`);
            let fetchedComments = response.data;
            fetchedComments.sort((a, b) => new Date(b['create_date']) - new Date(a['create_date']));
            setComments(fetchedComments);
            setFilteredComments(fetchedComments);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                console.error('Error fetching comments:', error);
            }
        }
        setIsLoading(false);
    }, [currentUser, getDocumentId, logout, navigate]);


    useEffect(() => {
        const fetchDocument = async () => {
            try {
                fetchComments(documentId);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error('Error fetching comment:', error);
                }
            }
        };
        fetchDocument();
    }, [fetchComments, logout, navigate, documentId]);

    useEffect(() => {
        if (searchInput) {
            const lowercasedInput = searchInput.toLowerCase();
            const filtered = comments.filter(comment => {
                const createDate = new Date(comment['create_date']).toLocaleString('en-US', { timeZone: 'UTC' }).toLowerCase();
                const modifyDate = new Date(comment['modified_date']).toLocaleString('en-US', { timeZone: 'UTC' }).toLowerCase();
                return comment['comment'].toLowerCase().includes(lowercasedInput) ||
                    createDate.includes(lowercasedInput) || modifyDate.includes(lowercasedInput);
            });
            setFilteredComments(filtered);
        } else {
            setFilteredComments(comments);
        }
    }, [searchInput, comments]);

    const postComment = async (newComment) => {
        try {

            const response = await axios.post(`/reg/v1/user/discussions/${currentUser?.id}/${currentUser?.organization}/${getDocumentId}/`, { comment: newComment, doc_source: doc_source, document_id: documentId });
            
            setComments(prevComments => [...prevComments, response.data]);
            fetchComments();
            setInputValue('');
            setSearchInput('');
            setCommentSortOrder('desc');
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                console.error('Error posting comment:', error);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        postComment(inputValue);
    };

    const applyCommentFilters = () => {
        let filtered = [...comments];

        if (filters.startDate) {
            const [year, month, day] = filters.startDate.split('-');
            const startDate = new Date(year, month - 1, day);
            // console.log(startDate);
            startDate.setHours(0, 0, 0, 0);

            filtered = filtered.filter(comment => {
                const commentDate = new Date(comment.create_date);
                commentDate.setHours(0, 0, 0, 0);

                return commentDate >= startDate;
            });
        }

        if (filters.endDate) {
            const [year, month, day] = filters.endDate.split('-');
            const endDate = new Date(year, month - 1, day);
            // console.log(endDate);
            endDate.setHours(23, 59, 59, 999);

            filtered = filtered.filter(comment => {
                const commentDate = new Date(comment.create_date);
                commentDate.setHours(0, 0, 0, 0);

                return commentDate <= endDate;
            });
        }
        setIsFilterOpen(false);
        setAreFiltersActive(true);
        setFilteredComments(filtered);
    };
    const handleResetFilters = () => {
        setFilters({
            startDate: null,
            endDate: null
        });
        setAreFiltersActive(false);
        setFilteredComments(comments);
    };

    return (
        <div className="d-flex flex-column flex-column-fluid w-100 h-100 mb-7 border-top bg-white">
            <form className="mt-6 mx-4 d-flex flex-column" style={{ flexShrink: '0' }} onSubmit={handleSubmit}>
                <div style={{ position: 'relative', width: '100%', marginBottom: '10px' }}>
                    <textarea
                        id='postComment'
                        className='form-control form-control-lg form-control-solid'
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        style={{
                            backgroundColor: '#f0f0f0',
                            height: '130px',
                            overflow: 'auto',
                            textAlign: 'left',
                            resize: 'none'
                        }}
                        rows={10}
                        maxLength={1000}
                    />
                </div>
                <div style={{ color: '#6c757d', fontSize: '14px' }}>{`${inputValue.length}/1000`}</div>
                <div className='d-flex justify-content-end mt-5 mb-5'>
                    <button
                        type='submit'
                        id='kt_password_submit'
                        className='btn btn-primary me-2 px-6 btn-sm'
                        disabled={loading || isLoading}
                    >
                        {!loading && <span className='indicator-label'>Post Comment</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </form>
            <div className="separator separator-solid mt-3 mb-3"></div>
            <div className="d-flex flex-column-auto  align-items-center mt-5 mb-5 ">
                <div className="d-flex align-items-center position-relative mx-3 my-1">

                    <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-5' />
                    <input
                        type="search"
                        id="form1"
                        className="form-control form-control-solid w-800px ps-14"
                        placeholder="Search Comments"
                        aria-label="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        maxLength={200}
                        style={{ backgroundColor: '#f1faff' }}
                    />
                </div>
                <div className="d-flex flex-column-auto  ms-4" style={{ position: 'relative' }}>
                    <button
                        type='button'
                        className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center '
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                        <KTIcon iconName='filter' className='fs-2' /> Filter
                    </button>
                    {isFilterOpen && (
                        <div style={{ position: 'absolute', top: '100%', left: '0', zIndex: 9999, opacity: 1, backgroundColor: '#ffffff' }}>
                            <CommentFilter
                                filters={filters}
                                onFilterChange={handleFilterChange}
                                onApplyFilters={applyCommentFilters}
                                onResetFilters={handleResetFilters}
                            />
                        </div>
                    )}
                </div>
                <div className="d-flex align-items-center ms-4">
                    <button
                        type='button'
                        className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center '
                        onClick={toggleCommentSortOrder}
                    >
                        <KTIcon iconName={commentSortOrder === 'asc' ? 'arrow-up' : 'arrow-down'} className='fs-2' /> Sort
                    </button>
                </div>
            </div >
            {isLoading ? (
                <HourGlass height="40vh" />
            ) : (
                <CommentsCard comments={filteredComments} currentUser={currentUser} fetchComments={fetchComments} docId={documentId} isFilter={areFiltersActive} />
            )}
        </div >
    );
};

export default DiscussionViewer;    